._success {
  &__title {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    padding: 0 24px;
  }

  &__image {
    text-align: center;
    margin-top: 32px;
  }
}
