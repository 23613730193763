._news-page-inner {
  display: grid;
  gap: 20px;
  padding: 180px 0 164px;

  @include mobile {
    padding: 80px 0;
  }

  @include tablet-only {
    padding: 120px 0;
  }

  @include tablet {
    grid-template-columns: 220px 1fr;
    gap: 0;
  }

  &__aside {
    padding-right: 20px;
  }

  &__body {
    padding-top: 12px;
  }

  &__title {
    margin-top: 10px;

    @include mobile {
      font-size: 36px;
    }
  }

  &__tags {
    margin-top: 36px;
  }

  &__content {
    margin-top: 60px;
  }
}
