._competencies {
  padding: 72px 0 79px;

  &__title {
    text-align: center;
  }

  &__body {
    margin-top: var(--space4x);

    @include desktop {
      margin-top: 48px;
    }
  }
}
