._vacancies-inner-page {
  padding: 180px 0 150px;

  @include mobile {
    padding: 80px 0;
  }

  @include tablet-only {
    padding: 120px 0;
  }

  &__department {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 171%;
    color: var(--grey-color);
  }

  &__title {
    margin: 10px 0 0;
  }

  &__description {
    margin: 37px 0 0;
    font-size: 18px;
    line-height: 156%;
    padding-right: 38%;

    @include mobile {
      padding-right: 0;
    }
  }

  &__feedback {
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
  }

  &__content {
    margin-top: 60px;
  }
}
