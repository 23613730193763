:root {
  --font-primary: 'TTNorms', sans-serif;

  --primary-color: #3c5df2;
  --secondary-color: #ffffff;
  --black-color: #000000;
  --grey-color: rgba(0, 0, 0, 0.4);
  --white-color: #ffffff;

  --disabled-color: #838696;
  --danger-color: #e06850;

  --scrollbar-track-color: transparent;
  --scrollbar-color: rgba(128, 128, 128, 0.5);
  --scrollbar-side: 6px;

  --space: 8px;
  --space2x: calc(var(--space) * 2);
  --space3x: calc(var(--space) * 3);
  --space4x: calc(var(--space) * 4);
  --space5x: calc(var(--space) * 5);

  --gap: 20px;

  --delay: 0.2s;
  --timing-func: cubic-bezier(0.21, 0, 0.29, 1);

  --plyr-color-main: var(--primary-color);

  --shadow: rgba(0, 0, 0, 0.25) 0 5px 15px;

  //--header: 58px;
}

@include desktop {
  :root {
    //--header: 92px;
  }
}
