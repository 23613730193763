._footer {
  padding: 80px 0;
  background-color: #1d212d;
  overflow: hidden;
  margin-top: auto;
  margin-bottom: 0;

  @include mobile {
    text-align: center;
  }

  &__title {
    margin: 0;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    color: var(--white-color);

    @include mobile {
      font-size: 32px;
      line-height: 1.1em;
    }
  }

  &__briefing {
    @include clean;

    margin: 36px 0 0;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    color: var(--primary-color);
    text-decoration-line: underline;
    cursor: pointer;

    @include mobile {
      font-size: 24px;
      line-height: 1.2em;
    }

    &:hover {
      text-decoration-line: none;
    }
  }

  &__email {
    margin: 98px 0 0;

    @include mobile {
      margin-top: 64px;
    }

    a {
      font-size: 16px;
      line-height: 175%;
      color: rgba(255, 255, 255, 0.6);
      transition: color var(--delay) var(--timing-func);

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  &__phone {
    margin: 7px 0 0;

    a {
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      color: rgba(255, 255, 255, 0.6);
      transition: color var(--delay) var(--timing-func);

      @include mobile {
        font-size: 28px;
        line-height: 1.1em;
      }

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  &__bottom {
    @include touch {
      margin-top: 40px;
    }

    @include desktop {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 40px;
      align-items: center;
      margin-top: 80px;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    gap: 12px 48px;

    @include mobile {
      flex-direction: column;
      gap: 0;
    }
  }

  &__menu {
    @include clr;

    display: flex;
    align-items: center;
    gap: 12px 48px;

    @include mobile {
      flex-direction: column;
      gap: 0;
    }

    &-item {
      &.active {
        a {
          color: var(--primary-color);
        }
      }
    }

    &-link {
      display: inline-block;
      padding: 12px 0;
      font-weight: 500;
      font-size: 14px;
      color: var(--white-color);
      transition: color var(--delay) var(--timing-func);

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  &__copyright {
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    color: var(--white-color);

    @include touch {
      margin-top: 48px;
    }
  }
}
