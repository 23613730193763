._acid-treatments-and-reagents-page {
  padding: 224px 0 180px;
  display: grid;
  gap: 160px;

  @include mobile {
    padding: 80px 0;
    gap: 64px;
  }

  @include tablet-only {
    padding: 120px 0;
    gap: 100px;
  }

  &__start {
  }

  &__point {
    margin: 0;
    color: var(--grey-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 114%;
  }

  &__title {
    margin-top: 10px;
  }

  &__description {
    margin: 0;
    margin-top: 44px;
    font-size: 18px;
    line-height: 155%;

    @include desktop {
      padding-right: 40%;
    }
  }

  &__props {
    @include clr;

    padding-top: 16px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    gap: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 36px;

    @include desktop {
      border-top-width: 2px;
      gap: 45px;
      margin-top: 58px;
    }

    &-item {
      display: grid;
      gap: 4px;
    }

    &-value {
      font-size: 18px;
      font-weight: 500;
    }

    &-key {
      color: var(--grey-color);
      font-size: 18px;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 36px;

    @include desktop {
      margin-top: 72px;
    }
  }

  &__resume {
    padding: 60px 110px;
    border-radius: 20px;
    background-color: #f8f8f8;
    display: grid;
    gap: 48px;
    background-position: bottom left;
    background-repeat: no-repeat;

    @include mobile {
      padding: 16px;
      border-radius: 12px;
    }

    @include tablet {
      padding: 32px;
      grid-template-columns: 3fr 2fr;
    }

    @include desktop {
      padding: 60px 110px;
    }
  }

  &__price {
    &-title {
      margin: 0;
      font-size: 24px;
      font-weight: 500;
    }

    &-subtitle {
      margin: 29px 0 0;
      color: var(--primary-color);
      font-size: 32px;
      font-weight: 500;
    }

    &-text {
      margin-top: 47px;
    }
  }

  &__feedback {
  }
}
