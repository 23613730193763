._who-are-we {
  &__image {
    margin-top: 180px;

    @include mobile {
      margin-top: 40px;
    }

    @include tablet-only {
      margin-top: 92px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}
