._dropdown {
  position: relative;

  &__open-btn {
    @include clean();

    cursor: pointer;
  }

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    display: none;

    &.active {
      @include desktop {
        display: block;
      }
    }
  }

  &__body {
    @include desktop {
      position: absolute;
      top: calc(100% + 4px);
      left: -4px;
      background-color: var(--white-color);
      box-shadow: var(--shadow);
      border-radius: 4px;
      width: max-content;
      overflow: hidden;
      display: none;
      z-index: 21;
      opacity: 0;
      transform: translateY(-10px);
      transition: opacity var(--delay) var(--timing-func),
        transform var(--delay) var(--timing-func);
    }

    &--right {
      @include desktop {
        left: auto;
        right: -4px;
      }
    }

    &.active {
      @include desktop {
        display: block;
      }
    }

    &.visible {
      @include desktop {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
