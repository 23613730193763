@mixin clean() {
  padding: 0;
  border-radius: 0;
  text-decoration: none;
  border: none;
  box-shadow: none;
  background: none;
  outline: none;
}

@mixin clr {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}