._hamburger {
  --color: var(--text-color);
  --animation: calc(3 * var(--delay));
  --scale: 0.5;

  transform: scale(var(--scale));
  position: relative;
  display: block;
  width: 34px;
  height: 34px;
  -webkit-touch-callout: none;
  user-select: none;

  .path-burger {
    position: absolute;
    height: 68px;
    width: 68px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    mask: url(#mask);
    -webkit-mask-box-image: url(../img/mask.svg);
  }

  .animate-path {
    position: absolute;
    top: 0;
    left: 0;
    width: 68px;
    height: 68px;
  }

  .path-rotation {
    height: 34px;
    width: 34px;
    margin: 34px 34px 0 0;
    transform: rotate(0deg);
    transform-origin: 100% 0;

    &:before {
      content: '';
      display: block;
      width: 30px;
      height: 34px;
      margin: 0 4px 0 0;
      background: var(--color);
    }
  }

  .path {
    stroke-dasharray: 240;
    stroke-dashoffset: 240;
    stroke-linejoin: round;
  }

  .burger-icon {
    position: absolute;
    padding: 20px 16px;
    height: 68px;
    width: 68px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .burger-container {
    position: relative;
    height: 28px;
    width: 36px;
  }

  .burger-bun-top,
  .burger-bun-bot,
  .burger-filling {
    position: absolute;
    display: block;
    height: 4px;
    width: 36px;
    border-radius: 2px;
    background: var(--color);
  }

  .burger-bun-top {
    top: 0;
    transform-origin: 34px 2px;
  }

  .burger-bun-bot {
    bottom: 0;
    transform-origin: 34px 2px;
  }

  .burger-filling {
    top: 12px;
  }

  .burger-ring {
    position: absolute;
    width: 68px;
    height: 68px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .svg-ring {
    width: 68px;
    height: 68px;
  }

  &.is-open {
    .path {
      animation: dash-in var(--animation) linear normal;
      animation-fill-mode: forwards;
    }
    .animate-path {
      animation: rotate-in var(--animation) linear normal;
      animation-fill-mode: forwards;
    }
    .burger-bun-top {
      animation: bun-top-out var(--animation) linear normal;
      animation-fill-mode: forwards;
    }
    .burger-bun-bot {
      animation: bun-bot-out var(--animation) linear normal;
      animation-fill-mode: forwards;
    }
    .burger-filling {
      animation: burger-fill-out var(--animation) linear normal;
      animation-fill-mode: forwards;
    }
  }

  &.is-closed {
    .path {
      animation: dash-out var(--animation) linear normal;
      animation-fill-mode: forwards;
    }
    .animate-path {
      animation: rotate-out var(--animation) linear normal;
      animation-fill-mode: forwards;
    }
    .burger-bun-top {
      animation: bun-top-in var(--animation) linear normal;
      animation-fill-mode: forwards;
    }
    .burger-bun-bot {
      animation: bun-bot-in var(--animation) linear normal;
      animation-fill-mode: forwards;
    }
    .burger-filling {
      animation: burger-fill-in var(--animation) linear normal;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes rotate-out {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-in {
  0% {
    transform: rotate(360deg);
  }
  40% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes dash-in {
  0% {
    stroke-dashoffset: 240;
  }
  40% {
    stroke-dashoffset: 240;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash-out {
  0% {
    stroke-dashoffset: 0;
  }
  40% {
    stroke-dashoffset: 240;
  }
  100% {
    stroke-dashoffset: 240;
  }
}

@keyframes bun-top-out {
  0% {
    left: 0;
    top: 0;
    transform: rotate(0deg);
  }
  20% {
    left: 0;
    top: 0;
    transform: rotate(15deg);
  }
  80% {
    left: -5px;
    top: 0;
    transform: rotate(-60deg);
  }
  100% {
    left: -5px;
    top: 1px;
    transform: rotate(-45deg);
  }
}

@keyframes bun-bot-out {
  0% {
    left: 0;
    transform: rotate(0deg);
  }
  20% {
    left: 0;
    transform: rotate(-15deg);
  }
  80% {
    left: -5px;
    transform: rotate(60deg);
  }
  100% {
    left: -5px;
    transform: rotate(45deg);
  }
}

@keyframes bun-top-in {
  0% {
    left: -5px;
    bot: 0;
    transform: rotate(-45deg);
  }
  20% {
    left: -5px;
    bot: 0;
    transform: rotate(-60deg);
  }
  80% {
    left: 0;
    bot: 0;
    transform: rotate(15deg);
  }
  100% {
    left: 0;
    bot: 1px;
    transform: rotate(0deg);
  }
}

@keyframes bun-bot-in {
  0% {
    left: -5px;
    transform: rotate(45deg);
  }
  20% {
    left: -5px;
    bot: 0;
    transform: rotate(60deg);
  }
  80% {
    left: 0;
    bot: 0;
    transform: rotate(-15deg);
  }
  100% {
    left: 0;
    transform: rotate(0deg);
  }
}

@keyframes burger-fill-in {
  0% {
    width: 0;
    left: 36px;
  }
  40% {
    width: 0;
    left: 40px;
  }
  80% {
    width: 36px;
    left: -6px;
  }
  100% {
    width: 36px;
    left: 0;
  }
}

@keyframes burger-fill-out {
  0% {
    width: 36px;
    left: 0;
  }
  20% {
    width: 42px;
    left: -6px;
  }

  40% {
    width: 0;
    left: 40px;
  }

  100% {
    width: 0;
    left: 36px;
  }
}
