._project-tag {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 4px 20px 4px 4px;
  border-radius: 26px;
  background-color: #f8f8f8;
  transition: background-color var(--delay) var(--timing-func);

  &__icon {
    flex: 0 0 44px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    color: var(--text-color);
  }

  &:hover {
    background-color: #f1f1f1;
  }
}
