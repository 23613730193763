._swiper-pagination {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--space5x);
  bottom: calc(var(--space) / 2);

  .swiper-pagination-bullet {
    opacity: 1;
    background-color: var(--disabled-color);
    width: var(--space);
    height: var(--space);
    border-radius: 50%;
    transition: transform var(--delay) var(--timing-func);

    &-active {
      background-color: var(--text-color);
      transform: scale(1.5);
    }
  }
}
