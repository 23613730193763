*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-feature-settings: 'liga' off;
}

* {
  &::selection {
    background-color: var(--primary-color);
    color: var(--white-color);
  }
}

html {
  overflow-y: auto;
  scroll-behavior: smooth;

  &.modal {
    overflow: hidden;
  }

  &.mobile-menu {
    @include touch {
      overflow: hidden;
    }
  }
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-primary), sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;

  @include desktop {
    font-size: 16px;
  }
}

body {
  --bg-color: var(--white-color);
  --text-color: var(--black-color);

  background-color: var(--bg-color);
  color: var(--text-color);
  overflow-x: hidden;

  &._full-height {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

#jGrowl {
  display: none !important;
}

.grecaptcha-badge {
  opacity: 0;
}
