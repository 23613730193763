._page-title {
  margin: 0;
  font-weight: 500;
  font-size: 60px;
  line-height: 71px;
  letter-spacing: -2px;

  @include touch {
    font-size: 42px;
    line-height: 1.1em;
  }
}
