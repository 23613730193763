._about-numbers {
  padding: calc(2 * var(--space5x)) 0;
  background-color: #f8f8f8;

  @include desktop {
    padding: 125px 0;
  }

  &__title {
    text-align: center;
  }

  &__body {
    @include clr;

    margin-top: 24px;
    display: grid;
    gap: var(--space5x);

    @include tablet {
      margin-top: 40px;
      grid-template-columns: repeat(3, 1fr);
      gap: var(--space5x) 0;
    }

    @include desktop {
      margin-top: 70px;
      gap: 70px 0;
    }
  }

  &__item {
    text-align: center;

    &-number {
      display: block;
      font-weight: 500;
      font-size: 40px;
      line-height: 47px;
    }

    &-text {
      display: block;
      font-weight: 400;
      font-size: 19px;
      line-height: 28px;
    }
  }
}
