._cookie {
  position: fixed;
  left: 20px;
  bottom: 20px;
  width: 20px;
  height: 20px;
  z-index: 21;
  pointer-events: none;

  &__content {
    padding: 12px 24px;
    width: 440px;

    @include mobile {
      width: 280px;
    }

    p {
      margin: 1em 0;
      line-height: 1.2em;

      a {
        color: var(--primary-color);
      }
    }
  }
}
