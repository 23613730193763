._input {
  --padding: 11px;

  position: relative;

  input,
  textarea {
    @include clean;

    font-feature-settings: 'liga' off;
    background-color: transparent;
    height: 44px;
    width: 100%;
    border: none;
    border-bottom: 2px solid var(--text-color);
    padding: 0 var(--padding);
    font-family: var(--font-primary);
    color: var(--text-color);
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;

    &.empty {
      + label {
        transform: translate(0);
      }
    }

    &:focus {
      + label {
        transform: translateY(-20px) scale(0.7);
      }

      ~ ._input__border {
        transform: scaleX(1);
      }
    }

    + label {
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0 var(--padding);
      color: var(--grey-color);
      font-weight: 500;
      font-size: 16px;
      display: flex;
      align-items: center;
      transform-origin: left top;
      transform: translateY(-20px) scale(0.7);
      pointer-events: none;
      transition: transform var(--delay) var(--timing-func);
    }
  }

  textarea {
    @include hide-scrollbar;

    resize: none;
    padding: 10px;
  }

  &__border {
    content: '';
    position: absolute;
    height: 2px;
    bottom: 0;
    left: auto;
    right: auto;
    background-color: var(--primary-color);
    width: 100%;
    transform: scaleX(0);
    transition: transform calc(1.5 * var(--delay)) var(--timing-func);
  }
}
