._form {
  display: grid;
  gap: var(--space4x);

  &__group {
    &--bottom-large {
      margin-bottom: 12px;
    }

    &--center {
      display: flex;
      justify-content: center;
    }
  }

  &__rules {
    text-align: center;
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);

    a {
      color: var(--primary-color);
      text-decoration: underline;
    }
  }
}
