._feedback {
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin: 0;
  }

  &__description {
    @include clr;

    margin-top: 32px;

    &-item {
      display: flex;
      align-items: baseline;
      gap: 8px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    &-key,
    &-value {
      font-size: 14px;
      line-height: 114%;
    }

    &-key {
      color: rgba(0, 0, 0, 0.4);
    }

    &-value {
      font-weight: 500;
    }
  }

  &__form {
    margin-top: 40px;
  }
}
