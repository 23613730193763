@mixin menu-link {
  display: block;
  font-weight: 500;
  font-size: 14px;
  padding: var(--space2x) 0;
  color: var(--black-color);
  transition: color var(--delay) var(--timing-func);

  &:hover {
    color: var(--primary-color);
  }
}

._header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  z-index: 10;
  transition: top calc(3 * var(--delay)) var(--timing-func),
    background-color calc(2 * var(--delay)) var(--timing-func);

  &.active {
    background-image: linear-gradient(
      180deg,
      var(--bg-color) 0%,
      transparent 100%
    );
  }

  &.hidden {
    top: calc(-20 * var(--space));
  }

  &__content {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include desktop {
      padding: 20px 0;
    }
  }

  &__logo {
    height: 26px;
    z-index: 1;

    @include desktop {
      transform: translateY(-3px);
    }

    &-desktop {
      @include mobile {
        display: none;
      }
    }

    &-mobile {
      @include tablet {
        display: none;
      }
    }

    svg {
      path {
        transition: fill var(--delay) var(--timing-func);
      }
    }

    &:hover {
      svg {
        path {
          fill: var(--primary-color);
        }
      }
    }
  }

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black-color);
    display: none;
    opacity: 0;
    transition: opacity calc(1.5 * var(--delay)) var(--timing-func);

    @include desktop {
      display: none;
    }

    &.active {
      @include touch {
        display: block;
      }
    }

    &.visible {
      @include touch {
        opacity: 0.5;
      }
    }
  }

  &__navigation {
    --padd: 48px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: calc(2 * var(--padd) + 1px);

    > *:not(:last-child) {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: calc(-1 * var(--padd) - 1px);
        width: 1px;
        height: 16px;
        transform: translateY(-50%);
        background-color: #0000001a;

        @include touch {
          display: none;
        }
      }
    }

    @include touch {
      position: fixed;
      right: -241px;
      bottom: 0;
      width: 240px;
      height: 100%;
      background-color: var(--bg-color);
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
      padding: calc(2 * var(--space3x)) var(--space3x);
      overflow-x: hidden;
      overflow-y: scroll;
      transition: right calc(1.5 * var(--delay)) var(--timing-func);
    }

    &.active {
      right: 0;
    }
  }

  &__menu {
    @include clr();

    display: flex;
    align-items: center;
    gap: 48px;

    @include touch {
      gap: 20px;
    }

    &-item {
      &.active {
        ._header__menu-link {
          color: var(--primary-color);
        }
      }
    }

    &-link {
      @include menu-link();

      @include touch {
        padding: calc(1.5 * var(--space)) 0;
      }
    }

    &--mobile {
      @include touch {
        display: block;
        width: 100%;
      }
    }
  }

  &__submenu {
    @include clr;

    padding: var(--space) var(--space2x);

    &-item {
      &.active {
        ._header__submenu-link {
          color: var(--primary-color);
        }
      }
    }

    &-link {
      @include menu-link();

      padding: calc(var(--space) / 2) 0;
    }
  }

  &__lang {
    &-btn {
      @include clean;
      @include menu-link;

      cursor: pointer;

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  &__hamburger {
    @include desktop {
      display: none;
    }
  }
}
