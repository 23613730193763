._not-found {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 24px;

  &__picture {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__title {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5em;
    text-align: center;
  }

  &__actions {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}
