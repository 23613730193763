._team-page {
  margin-bottom: 110px;

  @include desktop {
    margin-bottom: 440px;
  }

  &__start {
    position: relative;
    margin-bottom: 80px;

    @include desktop {
      margin-bottom: 240px;
    }

    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
            180deg,
            rgba(26, 26, 26, 0.13) 0%,
            #1a1a1a 100%
          ),
          linear-gradient(0deg, rgba(26, 26, 26, 0.4), rgba(26, 26, 26, 0.4));
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-content {
      min-height: 100vh;
      display: flex;
      align-items: flex-end;
      padding: 80px 0;
      transform: translateZ(0);

      @include desktop {
        padding: 150px 0;
      }
    }

    &-title {
      font-weight: 500;
      font-size: 72px;
      line-height: 1.2em;

      @include tablet {
        font-size: 100px;
        line-height: 118px;
      }
    }
  }

  &__ceo {
    margin: 50px 0;

    @include desktop {
      margin: 200px 0;
    }

    &-item {
      &:not(:last-child) {
        margin-bottom: 50px;

        @include desktop {
          margin-bottom: 200px;
        }
      }

      &:nth-child(2n) {
        ._team-page__ceo-member {
          flex-direction: row-reverse;
          text-align: right;
        }
      }
    }

    &-member {
      @include tablet {
        display: flex;
        align-items: center;
        gap: var(--space3x);
      }

      &-photo {
        font-size: 0;

        img {
          max-width: 100%;
        }
      }

      &-desc {
        display: grid;
      }

      &-name {
        font-weight: 500;
        font-size: 40px;
        line-height: 1.2em;

        @include tablet {
          font-size: 58px;
          line-height: 68px;
        }
      }

      &-status {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        margin-top: var(--space2x);
      }
    }
  }

  &__department {
    margin-bottom: 50px;

    @include desktop {
      margin-bottom: 200px;
    }

    &-title {
      font-weight: 500;
      font-size: 36px;
      line-height: 1.2em;

      @include tablet {
        font-size: 58px;
        line-height: 68px;
      }
    }

    &-list {
      margin-top: var(--space3x);
      display: grid;
      gap: var(--space3x);

      @include tablet {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &__member {
    &-photo {
      font-size: 0;
      text-align: center;

      img {
        max-width: 100%;
        object-fit: contain;
      }
    }

    &-name {
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
      margin-top: var(--space);

      @include tablet {
        margin-top: var(--space2x);
      }
    }
  }
}
