._news-preview {
  position: relative;
  display: grid;
  gap: 16px;

  &:hover {
    color: var(--primary-color);
  }

  &__body {
    display: grid;
    gap: 12px;
  }

  &__title {
    font-size: 28px;
    font-weight: 500;
    line-height: normal;
    transition: color var(--delay) ease-in-out;
  }

  &__intro {
    margin: 0;
    font-size: 18px;
    line-height: 28px;
    color: var(--black-color);
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
  }
}
