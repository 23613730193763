._slider-one-slide {
  display: grid;

  @include tablet {
    grid-template-columns: 52px 1fr 52px;
    align-items: center;
    gap: 20px;
  }

  @include desktop {
    gap: 70px;
  }

  &__nav {
    @include mobile {
      display: none;
    }
  }

  &__slide {
    border-radius: 20px;
    border: 2px solid #353749;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}
