._course-program {
  &__title {
    margin: 0;
    font-size: 40px;
    line-height: 1em;
    font-weight: 500;

    @include mobile {
      text-align: center;
    }
  }

  &__list {
    display: grid;
    gap: 12px;
    margin-top: 38px;
  }

  &__item {
    border-radius: 16px;
    background: #f8f8f8;
    overflow: hidden;

    &-header {
      border: none;
      width: 100%;
      display: grid;
      gap: 12px;
      text-align: left;
      padding: 20px 24px;
      cursor: pointer;
      border-radius: 16px;
      overflow: hidden;
      transition: background-color var(--delay) var(--timing-func);

      &:hover {
        background-color: var(--primary-color);
        color: var(--white-color);
      }
    }

    &-date,
    &-title {
      transition: color var(--delay) var(--timing-func);
    }

    &-date {
      font-size: 16px;
      font-weight: 400;
      line-height: 175%;
    }

    &-title {
      margin: 0;
      font-size: 32px;
      font-weight: 500;

      @include mobile {
        font-size: 20px;
        line-height: 1em;
      }
    }

    &-text {
      overflow: hidden;
    }

    &-content {
      padding: 16px 24px;

      &:empty {
        display: none;
      }
    }
  }
}
