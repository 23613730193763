._btn {
  @include clean;

  font-feature-settings: 'liga' off;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  height: 48px;
  border-radius: 24px;
  white-space: nowrap;
  cursor: pointer;
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 14px;
  background-color: var(--primary-color);
  color: var(--white-color);
  border: 2px solid var(--primary-color);
  transition: border-color var(--delay) var(--timing-func),
    color var(--delay) var(--timing-func),
    background-color var(--delay) var(--timing-func);

  svg rect,
  svg path {
    transition: all var(--delay) var(--timing-func);
  }

  &:hover {
    background-color: transparent;
    color: var(--primary-color);
  }

  &--small {
    height: 36px;
    border-radius: 18px;
    padding: 0 18px;
  }

  &--icon {
    height: 52px;
    border-radius: 26px;
    padding: 0;

    span {
      height: 52px;

      rect {
        fill: var(--primary-color);
      }
    }
  }

  &--circle {
    width: 52px;

    &:hover {
      span {
        rect {
          fill: transparent;
        }

        path {
          fill: var(--primary-color);
          stroke: var(--primary-color);
        }
      }
    }
  }

  &--block {
    width: 100%;
  }

  &--nav-menu {
    height: 36px;
  }

  &--link {
    border-color: transparent;
    background-color: transparent;
    color: var(--primary-color);
  }

  &--text {
    border-color: transparent;
    background-color: transparent;
    color: var(--text-color);

    &:hover {
      color: var(--primary-color);
    }
  }

  &--outline {
    color: var(--text-color);
    border-color: var(--text-color);
    background-color: transparent;

    &:hover {
      border-color: var(--primary-color);
    }
  }
}
