._feedback-block {
  padding: 120px 0 88px;

  @include mobile {
    padding: 80px 0 40px;
    margin-left: calc(-1 * var(--space2x));
    margin-right: calc(-1 * var(--space2x));
  }

  &__card {
    background-color: #f8f8f8;
    padding: 32px;
    border-radius: 0;

    @include tablet {
      padding: 32px 64px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
      align-items: center;
      border-radius: 20px;
    }

    @include desktop {
      padding: 60px 110px;
      gap: 130px;
    }
  }

  &__info {
  }

  &__title {
    margin: 0;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;

    @include touch {
      font-size: 32px;
      line-height: 1.1em;
    }
  }

  &__description {
    margin: 60px 0 0;
    font-size: 18px;
    line-height: 28px;

    @include touch {
      margin-top: 30px;
    }
  }

  &__form {
    padding-left: 58px;

    @include mobile {
      margin-top: 60px;
    }

    @include touch {
      padding-left: 0;
    }
  }
}
