._accordion {
  --padding: 30px 24px;

  @include mobile {
    --padding: 16px;
  }

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  &__header {
  }

  &__button {
    position: relative;
    padding: var(--padding);
    padding-right: 92px;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    color: var(--black-color);
    font-size: 32px;
    font-weight: 500;
    cursor: pointer;
    transition: color var(--delay) var(--timing-func);

    @include mobile {
      font-size: 20px;
      padding-right: 72px;
    }

    &-icon {
      position: absolute;
      right: 22px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      font-size: 0;
      border-radius: 28px;
      border: 2px solid var(--black-color);
      padding: 9px 17px;
      transition: height var(--delay) var(--timing-func),
        border var(--delay) var(--timing-func);

      @include mobile {
        padding: 8px;
      }

      svg {
        transition: transform var(--delay) var(--timing-func);

        path {
          transition: stroke var(--delay) var(--timing-func);
        }
      }
    }

    &:hover {
      color: var(--primary-color);

      ._accordion__button-icon {
        border-color: var(--primary-color);
      }

      svg {
        path {
          stroke: var(--primary-color);
        }
      }
    }

    &[aria-expanded='true'] {
      svg {
        transform: rotate(45deg);
      }
    }
  }

  &__panel {
    overflow: hidden;

    &-content {
      padding: var(--padding);
      padding-top: 0;

      &:empty {
        display: none;
      }
    }
  }
}
