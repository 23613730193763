._start-screen {
  padding: 238px 0 140px;

  @include mobile {
    padding: 80px 0;
  }

  @include tablet-only {
    padding: 140px 0;
  }

  &__content {
    @include mobile {
      text-align: center;
    }

    @include tablet {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      gap: 40px;
    }
  }

  &__title {
    margin: 0;
    font-weight: 500;
    font-size: 60px;
    line-height: 71px;
    letter-spacing: -2px;

    @include touch {
      font-size: 42px;
      line-height: 1.1em;
    }
  }

  &__description {
    margin: 49px 0 0;
    font-weight: 400;
    font-size: 19px;
    line-height: 22px;
    letter-spacing: -0.5px;
  }

  &__presentation {
    margin: 72px 0 0;
    padding-left: 3px;

    a {
      position: relative;
      display: inline-block;
      padding-left: 29px;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: var(--primary-color);

      @include mobile {
        padding-left: 0;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 100%;
        background-image: url('../img/pdf-icon.svg');
        background-position: left center;
        background-repeat: no-repeat;

        @include mobile {
          display: none;
        }
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -4px;
        right: 0;
        width: 0;
        height: 1px;
        background-color: var(--primary-color);
        transition: width calc(1.5 * var(--delay)) ease-in-out;
      }

      &:hover {
        &::after {
          right: auto;
          left: 0;
          width: 100%;
        }
      }
    }
  }

  &__image {
    @include mobile {
      display: none;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__projects {
    margin-top: 133px;

    @include mobile {
      margin-top: 80px;
    }

    .swiper-wrapper {
      align-items: center;
    }

    .swiper {
      padding: 0 var(--space2x);

      @include fromto($container-full + 40px, 1440px) {
        padding: 0 calc((100vw - #{$container-full}) / 2);
      }

      @include fullhd {
        padding: 0 calc((100vw - #{$container-full}) / 2);
      }
    }

    .swiper-slide {
      height: 52px;
      display: flex;
      align-items: center;

      &.swiper-slide-active {
        @include widescreen {
          flex-grow: 1;
        }
      }
    }
  }
}
