._vertical-banner {
  background-color: #f8f8f8;
  border-radius: 20px;
  padding: 60px 60px 0;
  display: flex;
  flex-direction: column;

  @include mobile {
    padding: 20px;
  }

  &__title {
    margin: 0;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: var(--black-color);
  }

  &__description {
    margin-top: 50px;
    font-size: 18px;
    line-height: 156%;
    color: #000000;
    padding-bottom: 60px;

    @include mobile {
      margin-top: 32px;
    }

    &:has(+ ._vertical-banner__image) {
      margin-bottom: -20%;
      padding-bottom: 0;

      @include mobile {
        margin-bottom: -10%;
      }
    }
  }

  &__image {
    max-width: var(--image-width);
    width: 100%;
    margin: auto auto 0;
    transform: translateY(50%);

    img {
      width: 100%;
      height: auto;
    }
  }

  &--has-image {
    @include mobile {
      margin-bottom: calc(var(--image-width) / 4);
    }
  }
}
