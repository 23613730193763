._notification {
  position: relative;
  pointer-events: auto;

  &__body {
    position: absolute;
    bottom: calc(100% + 4px);
    left: -4px;
    background-color: var(--white-color);
    box-shadow: var(--shadow);
    border-radius: 4px;
    width: max-content;
    overflow: hidden;
    display: none;
    z-index: 21;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity var(--delay) var(--timing-func),
      transform var(--delay) var(--timing-func);

    &.active {
      display: block;
    }

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
