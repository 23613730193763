._text-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.45em 0 1.15em;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol,
  ul,
  p {
    &:first-child {
      margin-top: 0;
    }
  }

  ol,
  ul,
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-weight: 500;
    font-size: 42px;
    line-height: 52px;
  }

  h2 {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }

  h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  h6 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  p {
    font-size: 20px;
    line-height: 28px;
    margin: 1em 0;
  }

  ul,
  ol {
    @include clr;

    margin: 1em 0;
    padding-left: 12px;

    li {
      font-size: 18px;
      line-height: 28px;
      padding-left: 16px;

      &:not(:last-child) {
        margin-bottom: 0.7em;
      }
    }
  }

  ul {
    li {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 14px;
        left: 0;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: var(--text-color);
      }
    }
  }

  img {
    max-width: 100%;
    width: 100%;
    cursor: pointer;

    &.auto {
      width: auto;
    }
  }

  a {
    color: var(--primary-color);
  }

  iframe {
    aspect-ratio: 16 / 9;
    margin: auto;
    width: 100%;
    height: auto;
    border: none;
  }
}
