._author-date {
  display: flex;
  align-items: center;
  gap: 12px;

  @include mobile {
    flex-wrap: wrap;
  }

  &__author {
    display: flex;
    align-items: center;

    &-picture {
      width: 28px;
      height: 28px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-name {
      margin: 0 0 0 8px;
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
      color: var(--black-color);
    }
  }

  &__date {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }
}
