._faq {
  &__title {
    text-align: center;
    font-size: 40px;
    font-weight: 500;
  }

  &__body {
    margin-top: 45px;
  }
}
