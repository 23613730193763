._capabilities {
  &__items {
    position: relative;
    margin-top: var(--space5x);

    @include touch {
      display: grid;
      gap: var(--space3x);
      justify-content: center;
    }
  }

  &__item {
    border-radius: 20px;
    color: var(--white-color);
    width: calc(100vw - 32px);
    overflow: hidden;

    @include tablet {
      width: 628px;
    }

    @include desktop {
      width: 880px;
      position: absolute;
      left: 0;
      transform-style: preserve-3d;
    }

    @include widescreen {
      width: 920px;
    }

    @include fullhd {
      width: 1190px;
    }

    &-content {
      @include tablet {
        display: grid;
        grid-template-columns: 1fr 430px;
      }
    }

    &-info {
      padding: 92px 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      @include mobile {
        padding: 32px;
      }

      @include tablet-only {
        padding: 44px 32px;
      }
    }

    &-title {
      font-weight: 500;
      font-size: 40px;
      line-height: 47px;

      @include touch {
        font-size: 24px;
        line-height: 1.1em;
      }

      @include tablet-only {
        font-size: 32px;
        line-height: 1.1em;
      }
    }

    &-link {
      margin-top: 20px;
      font-weight: 500;
      font-size: 16px;
      color: inherit;
      display: inline-flex;
      align-items: center;
      gap: 12px;

      span {
        flex: 0 0 52px;
        width: 52px;
        height: 52px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform var(--delay) ease-in-out;

        svg {
          max-width: 100%;
          max-height: 100%;
        }
      }

      &:hover {
        span {
          transform: translate(10px);
        }
      }
    }

    &-picture {
      @include mobile {
        display: none;
      }

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
