._partners {
  padding: 60px 0 120px;

  @include tablet {
    padding: 60px 0 180px;
  }

  &__title {
    text-align: center;
  }

  &__body {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: var(--space5x);
    flex-wrap: wrap;
    align-items: center;

    @include tablet {
      justify-content: space-between;
      flex-direction: row;
      gap: 40px 20px;
    }

    @include desktop {
      margin-top: 80px;
      justify-content: space-between;
      gap: 40px 80px;
    }

    @include fullhd {
      gap: 40px 160px;
    }
  }

  &__item {
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 0;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
