._horizontal-banner {
  border-radius: 20px;
  overflow: hidden;
  color: var(--white-color);

  @include tablet {
    display: grid;
    grid-template-columns: 1fr 420px;
    gap: 40px;
    align-items: center;
  }

  &__info {
    padding: 60px 0 60px 60px;

    @include mobile {
      padding: 20px;
    }
  }

  &__title {
    margin: 0;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }

  &__description {
    margin: 45px 0 0;

    @include mobile {
      margin-top: 32px;
    }

    p {
      font-size: 18px;
      line-height: 156%;
    }

    ul,
    ol {
      padding: 0;
      padding-left: 27px;
      margin: 1em 0;

      li {
        font-size: 18px;
        line-height: 156%;

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
  }

  &__bottom-title {
    margin: 58px 0 0;
    font-size: 32px;
    font-weight: 500;

    @include mobile {
      text-align: center;
      font-size: 24px;
      margin-top: 32px;
    }
  }

  &__action {
    margin-top: 58px;
    width: max-content;

    @include mobile {
      margin-top: 32px;
      width: 100%;
    }

    &-buttom {
    }

    &-text {
      margin: 8px 0 0;
      color: rgba(255, 255, 255, 0.8);
      font-size: 16px;
      line-height: 175%;
      padding: 0 50px;

      @include mobile {
        text-align: center;
        padding: 0;
      }
    }
  }

  &__image {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: stretch;

    @include mobile {
      display: none;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
