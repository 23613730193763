@mixin fromto($from, $to) {
  @media (min-width: $from) and (max-width: $to) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin touch {
  @media (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media (min-width: $desktop) and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin widescreen {
  @media (min-width: $widescreen) {
    @content;
  }
}

@mixin widescreen-only {
  @media (min-width: $widescreen) and (max-width: $fullhd - 1px) {
    @content;
  }
}

@mixin fullhd {
  @media (min-width: $fullhd) {
    @content;
  }
}
