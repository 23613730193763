._modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 100;
  display: none;

  &.open {
    ._modal__bg {
      opacity: 1;
    }

    ._modal__wrap {
      opacity: 1;
      transform: scale(1);
    }
  }

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity calc(2 * var(--delay)) var(--timing-func);
  }

  &__wrap {
    position: relative;
    max-width: 90%;
    max-height: 90vh;
    width: 416px;
    opacity: 0;
    padding: 26px 20px 20px;
    background-color: var(--bg-color);
    border-radius: 16px;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    transform: scale(0.75);
    transition: opacity calc(2 * var(--delay)) var(--timing-func),
      transform calc(2 * var(--delay)) var(--timing-func);
  }

  &__close {
    @include clean;

    position: absolute;
    top: 20px;
    right: 14px;
    cursor: pointer;

    svg {
      rect {
        fill: transparent;
        transition: fill var(--delay) var(--timing-func);
      }
    }

    &:hover {
      svg {
        rect {
          fill: #f8f8f8;
        }
      }
    }
  }
}
