._news-page {
  padding: 180px 0 164px;

  @include mobile {
    padding: 80px 0;
  }

  @include tablet-only {
    padding: 120px 0;
  }

  &__title {
  }

  &__filter {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

    ._btn.active {
      background-color: var(--primary-color);
      color: var(--white-color);
      border: 2px solid var(--primary-color);

      &:hover {
        background-color: transparent;
        color: var(--primary-color);
      }
    }
  }

  &__feed {
    margin-top: 60px;
    display: grid;
    gap: 60px;

    @include tablet {
      padding-right: 20%;
    }

    @include desktop {
      padding-right: 35%;
    }
  }
}
