._product-page {
  padding: 220px 0 0;

  @include mobile {
    padding: 80px 0 0;
  }

  @include tablet-only {
    padding: 120px 0 0;
  }

  &__title {
    margin: 0;

    @include mobile {
      text-align: center;
    }
  }

  &__description {
    margin: 45px 0 0;
    font-size: 18px;
    line-height: 21px;

    @include mobile {
      text-align: center;
      margin-top: 32px;
    }

    @include desktop {
      padding-right: 40%;
    }
  }

  &__actions {
    margin-top: 72px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    @include mobile {
      justify-content: center;
      margin-top: 40px;
    }
  }

  &__picture {
    margin-top: 162px;

    @include mobile {
      margin-top: 64px;
    }

    @include tablet-only {
      margin-top: 92px;
    }

    a {
      display: inline-block;
      width: 100%;

      &[data-video] {
        position: relative;
        overflow: hidden;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-image: url(/dist/img/icon-youtube.svg);
          background-position: center;
          background-repeat: no-repeat;
          pointer-events: none;
          transition: transform var(--delay) ease-in-out;
        }

        &:hover {
          &::after {
            transform: scale(1.05);
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
    }

    &--cover {
      border: 2px solid #353749;
      border-radius: 20px;
      overflow: hidden;
    }
  }

  &__prompt-1 {
    margin-top: 160px;

    @include mobile {
      margin-top: 64px;
    }
  }

  &__banners {
    --image-width: 520px;

    margin-top: 160px;
    display: grid;
    gap: 20px;

    @include mobile {
      --image-width: 240px;

      margin-top: 64px;
    }

    @include tablet {
      grid-template-columns: 1fr 1fr;
      padding-bottom: calc(var(--image-width) / 4);
    }

    @include desktop {
      padding-bottom: calc(var(--image-width) / 2);
    }

    &-wide {
      @include tablet {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }

  &__prompt-2 {
  }

  &__modules {
    margin-top: 220px;
    padding: 100px 0 140px;
    background-color: #f8f8f8;

    @include mobile {
      margin-top: 80px;
      padding: 50px 0 70px;
    }

    &-title {
      margin: 0;
      font-weight: 500;
      font-size: 40px;
      line-height: 47px;
      text-align: center;
    }

    &-body {
      margin-top: 100px;
      display: grid;
      gap: 100px 20px;

      @include tablet-only {
        margin-top: 140px;
        grid-template-columns: repeat(2, 1fr);
        gap: 128px 20px;
      }

      @include desktop {
        margin-top: 140px;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &__module {
    background-color: var(--bg-color);
    border-radius: 20px;
    padding: 0 32px 40px;

    &-image {
      max-width: 190px;
      width: 100%;
      height: 140px;
      margin: auto;
      transform: translateY(-55%);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-title {
      margin: -11% 0 0;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;

      @include mobile {
        margin: -70px 0 0;
      }
    }

    &-description {
      margin: 28px 0 0;
      font-size: 18px;
      line-height: 156%;
    }
  }

  &__info-block {
    margin: 60px 0;

    @include desktop {
      margin: 180px 0;
    }
  }
}
