._checkbox {
  position: relative;

  input[type="checkbox"] {
    display: none;

    + label {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      padding-left: 27px;
      cursor: pointer;

      &:hover {
        &::before {
          border-color: var(--primary-color);
        }
      }

      &::before {
        content: "";
        position: absolute;
        border: 1px solid var(--disabled-color);
        border-radius: 2px;
        width: 14px;
        height: 14px;
        top: 5px;
        left: 1px;
        transition: border-color var(--delay) var(--timing-func);
      }

      &::after {
        content: "";
        position: absolute;
        border: 2px solid var(--primary-color);
        border-top-style: none;
        border-right-style: none;
        width: 10px;
        height: 5px;
        top: 8px;
        left: 3px;
        transform: rotate(-45deg) scale(0);
        transition: transform var(--delay) var(--timing-func);
      }

      a {
        color: var(--primary-color);
        border-bottom: 1px solid;
        transition: border var(--delay);

        &:hover {
          border-bottom: 1px solid transparent;
        }
      }
    }

    &:checked {
      + label {
        &::after {
          transform: rotate(-45deg) scale(1);
        }
      }
    }
  }
}
