._main-slider-button-width {
  display: block;
  width: 280px;
  margin: auto;
  text-align: left;

  @include tablet {
    width: 346px;
  }
}

._main-slider {
  &__track {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 var(--space2x);
    background-image: linear-gradient(
      to right,
      transparent 80%,
      var(--text-color) 80%
    );
    background-size: 6px 1px;
    background-repeat: repeat-x;
    background-position: 50%;

    @include tablet {
      padding: 0;
      justify-content: center;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 25%;
      height: 100%;
      top: 0;
      z-index: 1;
    }

    &::before {
      left: 0;
      background-image: linear-gradient(to right, var(--bg-color), transparent);
    }

    &::after {
      right: 0;
      background-image: linear-gradient(to left, var(--bg-color), transparent);
    }

    &-points {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;

      span {
        position: absolute;
        background-color: var(--text-color);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        top: calc(50% - 8px);
      }
    }
  }

  &__title {
    @include clean;

    height: 48px;
    border-radius: 30px;
    padding: 0 17px 0 26px;
    border: 2px solid var(--text-color);
    background-color: var(--bg-color);
    color: var(--text-color);
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    transform: translateZ(0);
    z-index: 2;
    cursor: pointer;
    overflow: hidden;
    transition: color calc(2 * var(--delay)) var(--timing-func);

    &:hover {
      color: var(--primary-color);

      ._main-slider__title-arrow {
        transform: translate(10px);

        svg {
          path {
            stroke: var(--primary-color);
          }
        }
      }
    }

    &-text {
      position: relative;
      width: 100%;
      height: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: left;

      span {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &-arrow {
      margin-top: 4px;
      transition: transform calc(2 * var(--delay)) var(--timing-func);

      svg {
        path {
          stroke: var(--text-color);
          transition: stroke calc(2 * var(--delay)) var(--timing-func);
        }
      }
    }
  }

  &__swiper {
    margin-top: 20px;
    padding-bottom: var(--space4x);

    @include tablet {
      margin-top: 36px;
    }
  }

  &__slide {
    padding: 0 var(--space2x);
    width: 280px;
    cursor: pointer;
    opacity: 0.2;
    transition: opacity calc(2 * var(--delay)) var(--timing-func);

    @include tablet {
      padding: 0;
      width: 372px;
    }

    &.swiper-slide-active {
      opacity: 1;
    }

    &.swiper-slide-prev,
    &.swiper-slide-next {
      opacity: 0.6;
    }

    &:hover {
      opacity: 1;
    }

    p {
      font-size: 18px;
      line-height: 156%;

      &:not(:last-child) {
        margin-bottom: 0.5em;
      }
    }
  }
}
