._vacancies-page {
  padding: 180px 0 164px;

  @include mobile {
    padding: 80px 0;
  }

  @include tablet-only {
    padding: 120px 0;
  }

  &__title {
  }

  &__body {
    margin-top: 60px;
  }

  &__filter {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    &-number {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      padding-left: 9px;
      color: rgba(255, 255, 255, 0.5);
      transition: color var(--delay) var(--timing-func);
    }

    ._btn:hover {
      ._vacancies-page__filter-number {
        color: var(--primary-color);
      }
    }

    ._btn--text {
      ._vacancies-page__filter-number {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &__items {
    margin-top: 36px;
  }

  &__item {
    &.hidden {
      display: none;
    }

    &:hover {
      background-color: var(--primary-color);
      color: var(--white-color);
    }
  }
}
