._prompt-block {
  --padding-left: 200px;

  display: grid;
  grid-template-columns: var(--padding-left) 1fr;
  gap: 20px;

  @include mobile {
    grid-template-columns: 1fr;
  }

  @include tablet-only {
    --padding-left: 120px;
  }

  &__hint {
    grid-row-start: 1;
    grid-row-end: 3;
    padding-top: 6px;
    margin: 0;
    font-size: 16px;
    line-height: 1.4em;

    @include mobile {
      padding-top: 0;
    }
  }

  &__title {
    margin: 0;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;

    @include mobile {
      font-size: 24px;
      line-height: 1.1em;
    }
  }

  &__description {
    margin: 56px 0 0;
    font-size: 18px;
    line-height: 28px;
    padding-right: 40%;

    @include mobile {
      padding-right: 0;
      margin-top: 24px;
    }

    @include tablet-only {
      padding-right: 20%;
    }
  }

  &--full-width {
    grid-template-columns: 1fr;

    ._prompt-block__description {
      padding-right: 0;
    }
  }
}
