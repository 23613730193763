@mixin font-face($font-family, $url, $weight, $style: normal) {
  @font-face {
    font-family: "#{$font-family}";
    src: url("../fonts/#{$url}.woff2") format("woff2"),
      url("../#{$url}.woff") format("woff");
    font-weight: #{$weight};
    font-style: #{$style};
    font-display: swap;
  }
}
